import React, { useMemo } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { SWRConfig } from "swr";
import { objectKeysToCamelCase } from "../utils/string";
import useExceptionInterceptor from "./useExceptionInterceptor";
import {
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
  errorResponseInterceptor,
} from "../utils/api/interceptors";
import { CachedRequests } from "../utils/cachedRequests";
import env from "../src/../../environment";

axios.defaults.baseURL = env.REACT_APP_BASE_URL;
axios.interceptors.response.use(objectKeysToCamelCase);
axios.interceptors.response.use(responseInterceptor, errorResponseInterceptor);
axios.interceptors.request.use(requestInterceptor, requestInterceptorError);

const SWR_DEFAULT_VALUE = {
  suspense: false,
  fetcher: (...args) => axios(...args).then(({ data }) => data),
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  dedupingInterval: 5 * 60 * 1000,
};

DataFetchingConfig.propTypes = {
  /** Valores que serão sobrescritos na configuração padrão */
  value: PropTypes.object,
  children: PropTypes.node,
};

export default function DataFetchingConfig({ value = {}, children }) {
  useExceptionInterceptor();

  const config = useMemo(() => ({ ...SWR_DEFAULT_VALUE, ...value }), [value]);

  return <SWRConfig value={config}>{children}</SWRConfig>;
}

export const getCachedFetcher = cacheConfig => url =>
  CachedRequests.get(url, () => axios.get(url).then(({ data }) => data), cacheConfig).then(data => data);
