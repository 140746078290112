import { useEffect } from "react";
import axios from "axios";
import { useUsuario } from "../../hooks";

const makeExpectionInterceptor = (retries, regex) => async error => {
  const originalRequest = error.config;

  // só deve prosseguir se não for cancelada e não for a última chamada
  if (!originalRequest || originalRequest.lastCall) return Promise.reject(error);

  // só deve prosseguir se for get ou a url terminar em pesquisar, listar ou buscar
  if (originalRequest.method !== "get" && !regex.test(originalRequest.url)) return Promise.reject(error);

  // só deve prosseguir se o erro for do tipo 500
  if (error.response.status < 500) return Promise.reject(error);

  // se for a primeira chamada a ser repetida, registrar as variáveis de controle
  if (!originalRequest.callNumber) {
    originalRequest.callNumber = retries;
    originalRequest.lastCall = false;
  }
  // se for a última chamada, registrar a variável de controle
  else if (originalRequest === 1) originalRequest.lastCall = true;
  // se não for a primeira exceção e ainda tiver tentativas,
  // diminuir o número de chamadas
  else originalRequest.callNumber -= 1;

  return axios(originalRequest);
};

export default function useExceptionInterceptor() {
  const { featureToggles } = useUsuario();

  useEffect(
    () => {
      // flag vai ser o número de chamadas
      if (!featureToggles?.numTentativasExcecaoApi || !featureToggles?.regexExcecaoUrl) return;

      const expectionInterceptor = makeExpectionInterceptor(
        featureToggles.numTentativasExcecaoApi,
        featureToggles.regexExcecaoUrl
      );
      const interceptor = axios.interceptors.response.use(null, expectionInterceptor);

      // eslint-disable-next-line consistent-return
      return () => axios.interceptors.response.eject(interceptor);
    },
    [featureToggles?.numTentativasExcecaoApi, featureToggles?.regexExcecaoUrl]
  );
}
