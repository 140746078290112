import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import useDebounce from "../../hooks/useDebounce";
import SelectAutoComplete from "../../components/SelectAutoComplete";

const DEFAULT_DATA_SOURCE_CONFIG = {
  id: "id",
  text: "text",
};

AutoComplete.propTypes = {
  /**
   * String ou função com o endereço de consulta do select.
   * Caso seja passado uma função, ela recebe como parametro o termo de pesquisa (que sofre debounce automaticamente).
   * (debouncedSearch) => `/api/resource?search=${debouncedSearch}`
   * */
  api: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  /** Tempo de debounce entre a digitação do termo de busca e a requisição à api */
  debounceTime: PropTypes.number,
  /** Quantidade máxima de elementos na lista de resultados. Passado para o componente SelectAutoComplete */
  size: PropTypes.number,
  /** Define a estrutura da opção na input */
  dataSourceConfig: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }),
  remoteDataTextProp: PropTypes.string,
  /** Passado para o componente SelectAutoComplete */
  label: PropTypes.string,
  /** Passado para o componente SelectAutoComplete */
  inputProps: PropTypes.object,
  /** Passado para o componente SelectAutoComplete */
  disabled: PropTypes.bool,
  /** Passado para o componente SelectAutoComplete */
  shrinkLabel: PropTypes.bool,
  /**
   * Caso `true`, o AutoComplete é free solo, significando que a input do usuário não está amarrada as opções fornecidas.
   * Gerando um valor do tipo `{ id: null, text: "Meu valor não listado" }`
   */
  freeSolo: PropTypes.bool,
  /** From Fromik Field */
  form: PropTypes.object.isRequired,
  /** From Fromik Field */
  field: PropTypes.object.isRequired,
  apresentarError: PropTypes.bool,
  validarValor: PropTypes.func,
  validarNovo: PropTypes.bool,
};

AutoComplete.defaultProps = {
  disabled: false,
  inputProps: {},
  debounceTime: 500,
  size: 20,
  dataSourceConfig: DEFAULT_DATA_SOURCE_CONFIG,
  shrinkLabel: false,
  freeSolo: false,
  remoteDataTextProp: "descricao",
  apresentarError: false,
};

function AutoComplete({
  form,
  field,
  disabled = false,
  inputProps,
  api,
  debounceTime,
  size,
  dataSourceConfig: dsConfig,
  label,
  shrinkLabel,
  freeSolo,
  remoteDataTextProp,
  apresentarError,
  validarValor,
  validarNovo,
  ...props
}) {
  const dataSourceConfig = {
    ...DEFAULT_DATA_SOURCE_CONFIG,
    ...dsConfig,
  };
  const idProp = dataSourceConfig.id;
  const textProp = dataSourceConfig.text;

  const [search, setSearch] = useState(field?.value ? field.value[textProp] : "");
  const debouncedSearch = useDebounce(search, debounceTime);
  const isFuncApi = typeof api === "function";
  const [textoErro, setTextoErro] = useState("");

  const { data, isValidating } = useSWR(
    () => {
      // Se api for uma função, será recalculada depedendo do parametro debouncedSearch
      if (isFuncApi) {
        return debouncedSearch ? api(debouncedSearch) : null;
      }
      // Se api for uma string, apenas utiliza a string para a request
      return api;
    },
    {
      fallbackData: [],
      revalidateOnMount: true,
    }
  );

  const setValue = (id, text) => form.setFieldValue(field.name, { [idProp]: id, [textProp]: text }, true);

  const clearValue = () => setValue(null, "");

  const onChange = text => {
    form.setFieldTouched(field.name, true);

    if (!text) {
      clearValue();
      return;
    }
    const target = data.find(d => d[remoteDataTextProp] === text) || {};
    setValue(target?.id, text);
    setSearch(text);
  };

  const onBlur = e => {
    const { value: text } = e.target;
    const target = data.find(d => d[remoteDataTextProp] === text);

    if (!target && freeSolo) {
      setValue(null, text);
      return;
    }

    if (!target && !freeSolo) {
      clearValue();
      return;
    }

    setValue(target.id, text);
  };

  const onKeyUp = (e, valor = undefined) => {
    let text = null;
    if (e !== undefined) {
      text = e?.target.value;
    }
    if (valor !== undefined) {
      text = valor;
    }
    if (apresentarError) setTextoErro(validarValor(text));
    if (text !== field.value[textProp]) {
      setValue(null, text);
      setSearch(text);
    }
  };

  useEffect(() => {
    if (validarNovo !== undefined && field.value !== undefined) {
      onKeyUp(undefined, field.value.descricao);
    }
  }, []);

  return (
    <SelectAutoComplete
      label={label}
      options={data}
      value={{ id: field.value[idProp], descricao: field.value[textProp] }}
      onChange={onChange}
      disabled={disabled}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      size={size}
      isLoading={!!isValidating}
      shrinkLabel={shrinkLabel}
      inputProps={inputProps}
      error={textoErro}
      {...props}
    />
  );
}

export default AutoComplete;
