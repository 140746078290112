import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useIntl } from "react-intl";

import { Flex } from "..";

ExpansionPanelModal.propTypes = {
  hasError: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  title: PropTypes.string,
};

function ExpansionPanelModal({ title, icon, children, hasError, classes, ...props }) {
  const intl = useIntl();
  return (
    <ExpansionPanel classes={{ root: classes.expansionPanelRoot }} {...props}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>
            <span className={classes.icon}>{icon}</span>
            <Typography color={hasError ? "error" : "default"} variant="body1">
              {title}
            </Typography>
          </Flex>
          {hasError && (
            <Typography color="error" variant="caption">
              {intl.formatMessage({ defaultMessage: "Corrija o preenchimento dos campos *" })}
            </Typography>
          )}
        </Flex>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default withStyles({
  expansionPanelRoot: {
    overflow: "visible",
  },
  expansionPanelDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  icon: {
    marginRight: 12,
  },
})(ExpansionPanelModal);
